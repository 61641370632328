import './Footer.scss';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Footer(props: Props) {
  return (
    <footer>
      All Right Reserved Circle SAS 2021 -{' '}
      <strong
        className='termsButton'
        onClick={() => {
          props.setOpen(true);
        }}
      >
        Terms of Use{' '}
      </strong>
    </footer>
  );
}

export default Footer;
