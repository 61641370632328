const backgroundAnimation = (gsap: any) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: 'body',
      start: 'top+=10px top',
      end: 'bottom+=19000vh bottom',
      markers: false,
      scrub: 0.5,
    },
    onStart: () => {
      console.log('started');
    },
    onComplete: () => {
      console.log('ended');
    },
  });
  tl.to('.bubbleSmall', { yPercent: -165 });
  tl.to('.bubbleMedium', { yPercent: 65 }, '<');
  tl.to('.bubbleBig', { yPercent: -65 }, '<');
};

export default backgroundAnimation;
