const podAnimation = (gsap: any) => {
  const tl1 = gsap.timeline({
    scrollTrigger: {
      trigger: '.podSection',
      start: 'top center',
      end: 'center top',
      markers: false,
      scrub: 0.5,
    },
  });

  //Fade in Text
  tl1.from('.podTextBox', { opacity: 0, filter: 'blur(4px)', duration: 1 });

  if (!window.matchMedia('(max-width: 480px)').matches) {
    //Move the phone
    tl1.to('.phone', { yPercent: -70, duration: 3 }, '<');
  }
};

export default podAnimation;
