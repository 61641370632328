import './Background.scss';

import BubbleSmall from '../../../assets/images/bubbleSmall.png';
import BubbleMedium from '../../../assets/images/bubbleMedium.png';
import BubbleBig from '../../../assets/images/bubbleBig.png';

function Background() {
  return (
    <div className='bg'>
      <img src={BubbleSmall} alt="" className='bubbleSmall' />
      <img src={BubbleMedium} alt="" className='bubbleMedium' />
      <img src={BubbleBig} alt="" className='bubbleBig' />
    </div>
  );
}

export default Background;
