import { useEffect, useRef } from 'react';

import './Intro.scss';

function Intro() {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.oncanplaythrough = event => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      };
    }
  }, []);

  return (
    <>
      <section className='introSection maxSize'>
        <video ref={videoRef} muted className='vid'>
          <source src='images/introVideo.mp4' type='video/mp4' />
        </video>
        <div className='startBox' id='startBox'>
          <span className='start'>Start</span>
        </div>
      </section>
      <section className='noMinSize' id='mainTitle'>
        <div className='title d-flex justify-content-center '>
          <div className=''>
            <h1 className=' textCenter'>Run the City</h1>
            <p className='textCenter introP'>
              Let's Shape our Mobility <br /> Let's Run a Sustainable City
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Intro;
