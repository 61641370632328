const otherVehiclesAnimation = (gsap: any) => {
  if (!window.matchMedia('(max-width: 480px)').matches) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.vehiclesSection',
        start: 'top top',
        end: 'bottom+=4000px top',
        pin: true,
        pinSpacing: false,
        markers: false,
        scrub: 0.5,
      },
    });

    //Scooter in
    tl.from('.scooter', {
      yPercent: 150,
      xPercent: 50,
      filter: 'blur(10px)',
      duration: 5,
    });

    //Fade in time
    tl.from('.time1', { opacity: 0, duration: 1 });

    //Tempo
    tl.to(null, { duration: 2 }, '>');

    //Scooter out
    tl.to('.scooter', {
      yPercent: -110,
      xPercent: 200,
      transform: 'matrix3d(0.860306, 0.257611, 0, -0.00011, -0.097946, 0.414073, 0, 0.000058, 0, 0, 1, 0, -452.102688, -111.571347, 0, 1)',
      filter: 'blur(10px)',
      duration: 7,
    });

    //Fade out time
    tl.to('.time1', { opacity: 0, duration: 1 }, '<');

    //MotorBike in
    tl.from('.motorBikeContainer', {
      yPercent: 100,
      xPercent: 10,
      filter: 'blur(10px)',
      duration: 5,
    });

    //Fade in time
    tl.from('.time2', { opacity: 0, duration: 1 });

    //Tempo
    tl.to(null, { duration: 2 }, '>');

    //MotorBike out
    tl.to('.motorBike', {
      yPercent: -200,
      xPercent: 100,
      transform: 'matrix3d(0.860306, 0.257611, 0, -0.00011, -0.097946, 0.414073, 0, 0.000058, 0, 0, 1, 0, -452.102688, -111.571347, 0, 1)',
      filter: 'blur(10px)',
      duration: 15,
    });

    //Fade out time
    tl.to('.time2', { opacity: 0, duration: 1 }, '<');

    //Fade out text
    tl.to('.v-box2', { opacity: 0, duration: 1 }, '<');
  }
};

export default otherVehiclesAnimation;
