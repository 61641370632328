const solutionAnimation = (gsap: any) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.solutionSection',
      start: 'top bottom',
      end: 'top top',
      markers: false,
      scrub: 0.5,
    },
  });

  //Fade in text
  tl.to('.solutionTextBlock', { opacity: 1, duration: 1 });
};

export default solutionAnimation;
