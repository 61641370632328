const carAnimation = (gsap: any) => {
  if (!window.matchMedia('(max-width: 480px)').matches) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.carSection',
        start: 'top top',
        end: 'bottom+=1000px top',
        pin: true,
        markers: false,
        scrub: 0.5,
      },
    });

    //Tempo
    tl.from(null, { duration: 1 });

    //Fade in text
    tl.from('.v-box6', { opacity: 0, duration: 2 });

    //Car in
    tl.from(
      '.carVehicles',
      {
        yPercent: 160,
        xPercent: 25,
        filter: 'blur(10px)',
        duration: 2.5,
      },
      '<',
    );

    //Fade in time
    tl.from('.time3', { opacity: 0, duration: 1 });
  }
};

export default carAnimation;
