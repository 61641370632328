const outroAnimation = (gsap: any) => {
  const tl1 = gsap.timeline({
    scrollTrigger: {
      trigger: '.outroSection',
      start: 'top center',
      end: 'top top',
      markers: false,
      scrub: 0.5,
    },
  });

  //Fade in title
  tl1.from('.outroTitle', { opacity: 0, filter: 'blur(4px)', duration: 2 });

  //Fade in subtext
  tl1.to('.outroSubText', { opacity: 1, duration: 1 });
};

export default outroAnimation;
