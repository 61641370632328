const easyAnimation = (gsap: any) => {
  const tl1 = gsap.timeline({
    scrollTrigger: {
      trigger: '.easySection',
      start: 'top center',
      end: 'top top',
      markers: false,
      scrub: 0.5,
    },
  });

  //Fade in the text
  tl1.from('.easyTextBox', { opacity: 0, filter: 'blur(4px)', duration: 1 });

  if (!window.matchMedia('(max-width: 480px)').matches) {
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: '.easySection',
        start: 'top top',
        end: 'bottom+=500px top',
        markers: false,
        pin: true,
        scrub: 0.5,
      },
    });

    //Move the car
    tl2.from('.easyCar', { yPercent: 115, duration: 3 });

    //Tempo
    tl2.to(null, { duration: 1 });
  }
};

export default easyAnimation;
