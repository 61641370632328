import './Easy.scss';
import CarPark from '../../../assets/images/car-park-V2.png';

function Easy() {
  return (
    <section className='notFullScreen easySection'>
      <div className='d-flex flex-row h-100'>
        <div className='easyTextContainer d-flex flex-column justify-content-center '>
          <div className=' easyTextBox'>
            <h2 className='easyTitle'>
              Easy to Find,
              <br /> Easy to Ride,
              <br /> Easy to Park
            </h2>
            <p className='mt-4 easyText'>
              Free of any dock stations.
              <br /> Fast unlock with your smartphone.
              <br /> Maximize vehicule usage
              <br /> by making parking a breeze.
            </p>
          </div>
        </div>
        <div className='flex-column  align-items-center justify-content-center easyCar'>
          <img src={CarPark} alt='carPark' className='carPark' />
        </div>
      </div>
    </section>
  );
}

export default Easy;
