const cityAnimation = (gsap: any, imageCityRef: React.RefObject<HTMLImageElement>) => {
  //Number of frames
  const frameCount = 60;

  //Current frame
  const city = {
    frame: 0,
  };

  let images: HTMLImageElement[] = [];

  //SRC of the frame
  const currentFrame = (index: number) => {
    return `images/moving-city/web-circle-city_${index}.png`;
  };
  const render = () => {
    if (imageCityRef.current) {
      imageCityRef.current.src = images[Math.ceil(city.frame)].src;
    }
  };

  //Pre-load images
  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  //Render the first image
  images[0].onload = render;

  const tl1c = gsap.timeline({
    scrollTrigger: {
      trigger: '.citySection',
      start: 'top center',
      end: 'top center-=300px',
      markers: false,
      scrub: 0.5,
    },
  });

  //Fade in the city
  tl1c.fromTo('.moving-city', { opacity: '40%' }, { opacity: '100%' });

  const tl2c = gsap.timeline({
    scrollTrigger: {
      trigger: '.citySection',
      start: 'top top',
      end: 'bottom+=700px top',
      pin: true,

      markers: false,
      scrub: 0.5,
    },
  });

  //Rotate the city
  tl2c.to(city, {
    frame: frameCount - 1,
    duration: 5,
    onUpdate: render,
  });
  tl2c.to('.leftTag', { opacity: 1, duration: 1 });
  tl2c.to('.rightTag', { opacity: 1, duration: 1 }, '<');

  const tl3c = gsap.timeline({
    scrollTrigger: {
      trigger: '.citySection',
      start: 'bottom+=1px bottom-=100px',
      end: 'bottom center',
      markers: false,
      scrub: true,
    },
  });

  //Fade out the city
  tl3c.fromTo('.citySection', { opacity: '100%' }, { opacity: '40%' });
};

export default cityAnimation;
