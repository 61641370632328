const backLightAnimation = (gsap: any, imageBackLightRef: React.RefObject<HTMLImageElement>) => {
  //Number of frames
  const frameCount = 32;

  //Current frame
  const backLight = {
    frame: 0,
  };

  let images: HTMLImageElement[] = [];

  //SRC of the frame
  const currentFrame = (index: number) => {
    return `images/back-light/Circle_car-Backlite_${index}.png`;
  };

  const render = () => {
    if (imageBackLightRef.current) {
      imageBackLightRef.current.src = images[Math.ceil(backLight.frame)].src;
    }
  };

  //Pre-load images
  for (let i = 0; i < frameCount + 1; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  //Render the first image
  images[0].onload = render;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.backLightSection',
      start: 'top top',
      end: 'bottom+=500px top',
      pin: true,

      markers: false,
      scrub: 0.5,
    },
  });

  //Turn on the back light
  tl.to(
    backLight,
    {
      frame: frameCount,
      snap: { frame: 1 },
      duration: 3,
      onUpdate: render,
    },
    1,
  );
  tl.to('.leftTagBackLight', { opacity: 1, duration: 0.5 }, 1.5);
  tl.to('.rightTagBackLight', { opacity: 1, duration: 0.5 }, 2);
};

export default backLightAnimation;
