import './GlobalSystem.scss';
import Car from '../../../assets/images/Circle_car-front-left.png';

function GlobalSystem() {
  return (
    <section className='notFullScreen GSSection'>
      <div className='d-flex parentSize GSContainer'>
        <div className='GSTextContainer d-flex flex-column justify-content-center '>
          <div className='GSTextBox'>
            <h2 className='GSTitle'>
              More than a Car,
              <br /> a Global System Based <br />
              on Next Generation
              <br /> Technology
            </h2>
            <p className='mt-4 GSText'>
              Technologies embedded to <br />
              detect driving events and damages <br /> from telematics data and POD hardware <br />
              thanks to a Cloud Platform.
            </p>
          </div>
        </div>
        <div className='d-flex justify-content-center  car'>
          <div className='GSTag GSTag1'>
            <span>EASILY CLEANABLE</span>
          </div>
          <div className='GSTag GSTag4'>
            <span>AUTO DISINFECTION</span>
          </div>
          <div className='GSTag GSTag2'>
            <span>DOCKLESS</span>
          </div>
          <div className='GSTag GSTag3'>
            <span>SENSORS</span>
          </div>

          <img src={Car} alt='car' className='w-100' />
        </div>
        <div className=' resTag'>
          <div className='GSResTag '>
            <span>EASILY CLEANABLE</span>
          </div>
          <div className='GSResTag '>
            <span>AUTO DISINFECTION</span>
          </div>
          <div className='GSResTag '>
            <span>DOCKLESS</span>
          </div>
          <div className='GSResTag'>
            <span>SENSORS</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GlobalSystem;
