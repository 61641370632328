import './Outro.scss';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Outro(props: Props) {
  return (
    <section className='notFullScreen outroSection'>
      <div className='parentSize d-flex flex-column align-items-center justify-content-center'>
        <div className='outroTextContainer'>
          <h2 className='textCenter outroTitle'>Run the City</h2>
          <div className='outroSubText'>
            <p className='textCenter outroText'>Stay in Touch With the Next Mobility Revolution</p>
            <button
              className='btn btn-lg btn-outro letsTalkButton'
              onClick={() => {
                props.setOpen(true);
              }}
            >
              Let's Talk
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Outro;
