import './Car.scss';
import CarImg from '../../../assets/images/car.png';
import { ReactComponent as Path } from '../../../assets/images/path-car.svg';

function Car() {
  return (
    <section className='notFullScreen noMinSize carSection'>
      <div className='h-100 d-flex carVehiclesContainer'>
        <div className='v-box5 flex-column justify-content-center'>
          <div className=' flex-column time3'>
            <span className='hourCar'>6.13</span>
            <span className='hourCar2'>pm</span>
            <Path className='carPath' />
          </div>
        </div>
        <div className='v-box6 vehiclesTextContainer d-flex flex-column justify-content-center'>
          <div className='vehiclesTextBox'>
            <h2 className='vehiclesTitle'>
              Easy to Integrate <br />
              to any Multimodal Fleet
            </h2>
            <p className='mt-6 vehiclesText'>
              Easy to operate, our 100% digital system
              <br /> is offering total control and bespoke <br />
              fleet optimization.
            </p>
          </div>
        </div>
        <div className='carVehiclesRes'>
          <img src={CarImg} alt='carVehicles' className='carVehiclesResImg' />
        </div>
        <img src={CarImg} alt='carVehicles' className='carVehicles' />
      </div>
    </section>
  );
}

export default Car;
