import './Rgpd.scss';
import { useState } from 'react';

function Rgpd() {
  const [open, setOpen] = useState(true);
  return (
    <div className={open ? 'd-flex rgpd' : 'd-none rgpd'}>
      <p>
        We are using technologies like Cookies or Targeting and process personal data like browser information in order to analyse the way
        you are using our website. This helps us to improves your internet experience. We also use it in order to measure results or align
        our website content. Because we value your privacy, we are herewith asking your permission to use the following technologies.
      </p>
      <div className='d-flex justify-content-between'>
        <button
          className='btn btn-outro'
          onClick={() => {
            (function (w: any, d: any, s: any, l: any, i: any) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              let f = d.getElementsByTagName(s)[0];
              let j = d.createElement(s);
              let dl = l !== 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-WX4ZQLW');
            setOpen(false);
          }}
        >
          I agree
        </button>
        <button
          className='btn btn-outro'
          onClick={() => {
            setOpen(false);
          }}
        >
          I disagree
        </button>
      </div>
    </div>
  );
}

export default Rgpd;
