import './Header.scss';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Header(props: Props) {
  return (
    <header className='d-flex justify-content-between notFullScreen align-items-center'>
      <Logo />
      <button
        className='contactBtn btn'
        onClick={() => {
          props.setOpen(true);
        }}
      >
        Contact
      </button>
    </header>
  );
}

export default Header;
