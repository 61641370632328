import './Solution.scss';

function Solution() {
  return (
    <section className='solutionSection noMinSize'>
      <div className='d-flex flex-column justify-content-center mt-5'>
        <div className='solutionTextBlock'>
          <h2 className='textCenter solutionTitle'>
            A Unique Solution <br /> to Answer Urban Mobility Challenges
          </h2>
          <p className='mt-2 textCenter solutionText'>
            Sustainable urban mobility policies and society trends are changing <br /> the shape of our cities. More bicycle lanes, less
            space for traditional <br /> cars, more free-floating vehicles, more choices.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Solution;
