const introAnimation = (gsap: any) => {
  const tl1 = gsap.timeline({
    scrollTrigger: {
      trigger: '.introSection',
      start: 'top top',
      end: 'bottom center-=100px',
      markers: false,
      scrub: true,
    },
  });

  //Fade out the start btn
  tl1.to('.startBox', { opacity: 0, duration: 1 });

  if (!window.matchMedia('(max-width: 480px)').matches && !window.matchMedia('(max-width: 768px)').matches) {
    //Fade in the title
    tl1.from('.title', { opacity: 0, filter: 'blur(10px)', duration: 9 }, '<');
  }

  const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: '.introSection',
      start: 'top top',
      end: 'bottom center',
      pin: true,
      pinSpacing: false,
      markers: false,
      scrub: true,
    },
  });

  //Fade out the video
  tl2.to('.vid', { opacity: 0, duration: 1 }, '<');
};

export default introAnimation;
