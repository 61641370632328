import './BackLight.scss';

type Props = {
  imageBackLightRef: React.RefObject<HTMLImageElement>;
};

function BackLight(props: Props) {
  return (
    <section id='backLightSection' className='d-flex justify-content-center backLightSection'>
      <img ref={props.imageBackLightRef} src='images/back-light/Circle_car-Backlite_0.png' alt="" className='back-light' />
      <div className='d-flex align-items-center tagBackLight leftTagBackLight'>
        <span>Data</span>
        <span className='fitInBox'>Telemetry</span>
      </div>
      <div className='d-flex align-items-center justify-content-end tagBackLight rightTagBackLight'>
        <span>AI POWERED</span>
      </div>
    </section>
  );
}

export default BackLight;
