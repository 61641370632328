import './City.scss';

type Props = {
  imageCityRef: React.RefObject<HTMLImageElement>;
};

function City(props: Props) {
  return (
    <section id='citySection' className='d-flex justify-content-center citySection'>
      <img ref={props.imageCityRef} src='images/moving-city/web-circle-city_0.png' alt="" className='moving-city' />
      <div className='d-flex align-items-center tag leftTag'>
        <span>Low Impact</span>
      </div>
      <div className='d-flex align-items-center justify-content-end tag rightTag'>
        <span>city friendly</span>
      </div>
    </section>
  );
}

export default City;
