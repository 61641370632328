const experienceAnimation = (gsap: any) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.experienceSection',

      markers: false,
      scrub: 0.5,
    },
  });
  const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: '.experienceSection',
      end: 'bottom center',
      markers: false,
      scrub: 0.5,
    },
  });
  //Tempo
  tl.to(null, { duration: 3 });
  //Fade in
  tl2.from('.experienceTextBox', { opacity: 0, filter: 'blur(4px)', duration: 1 });
  if (!window.matchMedia('(max-width: 480px)').matches) {
    //Fade out the text
    tl.to('.experienceTextBox', { opacity: 0, filter: 'blur(4px)', duration: 1 });
  }
};

export default experienceAnimation;
