import './Experience.scss';
import { useEffect, useRef } from 'react';

function Experience() {
  const experienceTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {}, []);
  return (
    <section className='notFullScreen noMinSize experienceSection'>
      <div ref={experienceTextRef} className='d-flex flex-column justify-content-center align-items-center experienceTextBox'>
        <h2 className='textCenter experienceTitle'>
          "We are building a new experience
          <br /> of transportation for B2B mobility players <br /> to change the city"
        </h2>
        <p className='mt-2 textCenter experienceText'>
          <strong>Eric Boullier, Founder</strong>
          <br />
          Ex-CEO McLaren F1 Racing Team & Renault F1 Team
        </p>
      </div>
    </section>
  );
}

export default Experience;
