import { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Scrollbar from 'smooth-scrollbar';

//Import styles
import '../../assets/scss/global.scss';

//Import components
import Header from './Header/Header.component';
import Intro from './Intro/Intro.component';
import Solution from './Solution/Solution.component';
import Pod from './Pod/Pod.component';
import GlobalSystem from './GlobalSystem/GlobalSystem.component';
import Easy from './Easy/Easy.component';
import Experience from './Experience/Experience.component';
import Outro from './Outro/Outro.component';
import Footer from './Footer/Footer.component';
import City from './City/City.component';
import CityEnd from './CityEnd/CityEnd.component';
import BackLight from './BackLight/BackLight.component';
import OtherVehicles from './Vehicles/OtherVehicles.component';
import Car from './Vehicles/Car.component';
import Contact from './Contact/Contact.component';
import FrontLight from './FrontLight/FrontLight.component';
import Background from './Background/Background.component';
import Terms from './Terms/Terms.component';
import Rgpd from './Rgpd/Rgpd.component';
//Import animations
import introAnimation from '../../components/Landing/Intro/Intro.animation';
import cityAnimation from '../../components/Landing/City/City.animation';
import solutionAnimation from '../../components/Landing/Solution/Solution.animation';
import podAnimation from '../../components/Landing/Pod/Pod.animation';
import backLightAnimation from '../../components/Landing/BackLight/BackLight.animation';
import globalSystemAnimation from '../../components/Landing/GlobalSystem/GlobalSystem.animation';
import easyAnimation from '../../components/Landing/Easy/Easy.animation';
import otherVehiclesAnimation from '../../components/Landing/Vehicles/OtherVehicles.animation';
import carAnimation from '../../components/Landing/Vehicles/Car.animation';
import experienceAnimation from '../../components/Landing/Experience/Experience.animation';
import cityEndAnimation from '../../components/Landing/CityEnd/CityEnd.animation';
import outroAnimation from '../../components/Landing/Outro/Outro.animation';
import frontLightAnimation from '../../components/Landing/FrontLight/FrontLight.animation';
import backgroundAnimation from '../../components/Landing/Background/Background.animation';

gsap.registerPlugin(ScrollTrigger);

function Landing() {
  const imageCityRef = useRef<HTMLImageElement>(null);
  const imageBackLightRef = useRef<HTMLImageElement>(null);
  const imageCityEndRef = useRef<HTMLImageElement>(null);

  const [open, setOpen] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  useEffect(() => {
    //Init smoothScroll
    const scroller: HTMLElement | null = document.querySelector('.scroller');
    if (scroller) {
      const scrollbar = Scrollbar.init(scroller, {});
      ScrollTrigger.scrollerProxy('.scroller', {
        scrollTop(value) {
          if (arguments.length && value) {
            scrollbar.scrollTop = value; // setter
          }
          return scrollbar.scrollTop; // getter
        },
      });

      scrollbar.addListener(ScrollTrigger.update);
      ScrollTrigger.defaults({ scroller: '.scroller' });
      let startBox = document.getElementById('startBox');
      let mainTitle = document.getElementById('mainTitle');
      if (startBox) {
        startBox.addEventListener('click', () => {
          if (mainTitle) {
            scrollbar.scrollIntoView(mainTitle, { alignToTop: false, offsetBottom: 250 });
          }
        });
      }
    }

    //Init all animations
    introAnimation(gsap);
    cityAnimation(gsap, imageCityRef);
    solutionAnimation(gsap);
    podAnimation(gsap);
    backLightAnimation(gsap, imageBackLightRef);
    globalSystemAnimation(gsap);
    frontLightAnimation(gsap);
    easyAnimation(gsap);
    otherVehiclesAnimation(gsap);
    carAnimation(gsap);
    experienceAnimation(gsap);
    cityEndAnimation(gsap, imageCityEndRef);
    outroAnimation(gsap);
    backgroundAnimation(gsap);

    window.onresize = function () {
      if (!window.matchMedia('(max-width: 1024px)').matches) {
        window.location.reload();
      }
    };
  }, []);
  return (
    <>
      <Header setOpen={setOpen} />
      <div className='scroller'>
        <Intro />
        <Experience />
        <City imageCityRef={imageCityRef} />
        <Solution />
        <Pod />
        <BackLight imageBackLightRef={imageBackLightRef} />
        <GlobalSystem />
        <FrontLight />
        <Easy />
        <OtherVehicles />
        <Car />
        <CityEnd imageCityEndRef={imageCityEndRef} />
        <Outro setOpen={setOpen} />
        <Footer setOpen={setOpenTerms} />
      </div>
      <Background />
      <Contact open={open} setOpen={setOpen} />
      <Terms open={openTerms} setOpen={setOpenTerms} />
      <Rgpd></Rgpd>
    </>
  );
}

export default Landing;
