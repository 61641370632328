const globalSystemAnimation = (gsap: any) => {
  const tl1 = gsap.timeline({
    scrollTrigger: {
      trigger: '.GSSection',
      start: 'top center',
      end: 'top top',

      scrub: 0.5,
    },
    onComplete: () => {},
  });

  //Fade in the text
  tl1.from('.GSTextBox', { opacity: 0, duration: 1 });

  if (!window.matchMedia('(max-width: 480px)').matches && !window.matchMedia('(max-width: 768px)').matches) {
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: '.GSSection',
        start: 'top top',
        end: 'bottom+=200px top',
        pin: true,
        markers: false,
        scrub: 0.5,
      },
    });
    //Move the car
    tl2.to('.car', { xPercent: -100, duration: 5 });

    //Reveal tags
    tl2.to('.GSTag', { opacity: 1, duration: 1 });

    //Tempo
    tl2.to(null, { duration: 3 }, '>');
  }
};

export default globalSystemAnimation;
