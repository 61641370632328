import './OtherVehicles.scss';
import ScooterImg from '../../../assets/images/scooter.png';
import MotorBikeImg from '../../../assets/images/motorBike.png';
import { ReactComponent as ScooterPath } from '../../../assets/images/path-scooter.svg';
import { ReactComponent as MBPath } from '../../../assets/images/path-motorBike.svg';

function OtherVehicles() {
  return (
    <section className='notFullScreen noMinSize vehiclesSection'>
      <div className='d-flex vehiclesContainer h-100'>
        <div className='v-box1 justify-content-center '>
          <div className='flex-column time1'>
            <span className='hour'>8.15</span>
            <span className='hour2'>am</span>
            <ScooterPath className='scooterPath' />
          </div>
        </div>
        <div className='v-box2 vehiclesTextContainer d-flex flex-column justify-content-center '>
          <div className='vehiclesTextBox'>
            <h2 className='vehiclesTitle'>
              A cozy experience <br />
              for micro mobility <br />
              addicts
            </h2>
            <p className='mt-4 vehiclesText'>
              Upgrade your fleet with a tailor-made <br />
              mobility vehicle to empower your clients’
              <br /> mobility freedom
            </p>
          </div>
        </div>
        <img src={ScooterImg} alt='scooter' className='scooter' />
      </div>

      <div className='motorBikeRes'>
        <img src={MotorBikeImg} alt='motorBike' className='motorBikeResImg' />
      </div>
      <div className='h-100 d-flex flex-row motorBikeContainer'>
        <div className='v-box3 flex-column justify-content-center '>
          <div className=' flex-column time2'>
            <span className='hour'>2.12</span>
            <span className='hour2'>pm</span>
            <MBPath className='motorBikePath' />
          </div>
        </div>
        <img src={MotorBikeImg} alt='motorBike' className='motorBike' />
      </div>
    </section>
  );
}

export default OtherVehicles;
