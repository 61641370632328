import { ReactComponent as CloseCross } from '../../../assets/images/cross.svg';
import './Terms.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Terms(props: Props) {
  return (
    <div className={props.open ? 'd-flex flex-column terms ' : 'd-none flex-column terms '}>
      <div className='d-flex justify-content-end'>
        <CloseCross
          className='cross'
          onClick={() => {
            props.setOpen(false);
          }}
        />
      </div>
      <div className='d-flex mt-4  '>
        <div>
          <h2 className='termSubtitle'>1 - Editeur :</h2>
          <p className='termsText'>
            Le site internet circle.fr est édité par CIRCLE Société par Actions Simplifiées au capital de 105 545,70 euros, immatriculée au
            Registre du Commerce et des Sociétés de Paris sous le numéro 877 986 992, ayant son siège social 55 rue La Boétie, 75008 Paris
            et dont le N° de TVA intracommunautaire est FR96 877 986 992. CIRCLE peut être contacté à l'adresse e-mail suivante :
            contact@circle-car.com
          </p>
          <h2 className='termSubtitle'>2 - Hébergeur :</h2>
          <p className='termsText'>
            Ce site est hébergé sur : Google Cloud Platform - société Google LLC , sise au 1600 Amphitheatre Parkway, MOUNTAIN VIEW CA
            94043, États-Unis. Google Cloud Platform peut être contacter à l'adresse suivante : https://cloud.google.com/contact/?hl=fr Le
            nom de domaine est acheté auprès de la société OVH (https://www.ovh.com/fr/), société par actions simplifiées, au capital social
            de 10 174 560 €, inscrit au Registre du Commerce et des Sociétés de Lille Métropole sous le numéro 424 761 419, ayant son siège
            social au 2 rue Kellermann, 59100 Roubaix. OVH peut être contacter à l'adresse suivante : https://www.ovh.com/fr/support/
          </p>
          <h2 className='termSubtitle'>3 - Information, cookies et données personnelles :</h2>
          <p className='termsText'>
            Les informations consultables sur le site le sont à titre purement informatif et ne sauraient constituer une offre de services.
            Seul des cookies nécessaires au fonctionnement du site sont déposés à l’occasion de votre navigation sur celui-ci. Aucune donnée
            personnelle au sens du Règlement (UE) 2016/679 du 27 avril 2016 n’est collectée et traitée à l’occasion de votre visite sur le
            site internet.
          </p>
          <h2 className='termSubtitle'>4 - Modifications :</h2>
          <p className='termsText'>
            CIRCLE se réverse le droit de modifier le contenu des présentes mentions légales à tout moment et sans préavis. Date de la
            dernière mise à jour : 13 avril 2021
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;
