const cityEndAnimation = (gsap: any, imageCityEndRef: React.RefObject<HTMLImageElement>) => {
  //Number of frames
  const frameCount = 71;

  //Current frame
  const cityEnd = {
    frame: 0,
  };

  let images: HTMLImageElement[] = [];

  //SRC of the frame
  const currentFrame = (index: number) => {
    return `images/closing-city/circle-city-close_${index}.png`;
  };

  const render = () => {
    if (imageCityEndRef.current) {
      imageCityEndRef.current.src = images[Math.ceil(cityEnd.frame)].src;
    }
  };

  //Pre-load images
  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  //Render the first image
  images[0].onload = render;

  const tl1c = gsap.timeline({
    scrollTrigger: {
      trigger: '.cityEndSection',
      start: 'top center',
      end: 'top center-=300px',
      markers: false,
      scrub: 0.5,
    },
  });

  //Fade in the city
  tl1c.fromTo('.closing-city', { opacity: '40%' }, { opacity: '100%' });

  const tl2c = gsap.timeline({
    scrollTrigger: {
      trigger: '.cityEndSection',
      start: 'top top',
      end: 'bottom+=2000px top',
      pin: true,
      pinSpacing: false,
      markers: false,
      scrub: 0.5,
    },
  });

  //Close the city
  tl2c.to(cityEnd, {
    frame: frameCount - 1,
    duration: 5,
    onUpdate: render,
  });
  tl2c.fromTo('.cityEndSection', { opacity: '100%' }, { opacity: '0%' });
};

export default cityEndAnimation;
