import './FrontLight.scss';
import frontLightImg from '../../../assets/images/frontLight.png';

function FrontLight() {
  return (
    <section id='frontLightSection' className='d-flex justify-content-center frontLightSection'>
      <img src={frontLightImg} alt="" className='front-light' />
      <div className='d-flex align-items-center tagFrontLight leftTagFrontLight'>
        <span>DASHCAM FOOTAGE</span>
      </div>
      <div className='d-flex align-items-center justify-content-end tagFrontLight rightTagFrontLight'>
        <span>INCIDENT DETECTION</span>
      </div>
    </section>
  );
}

export default FrontLight;
