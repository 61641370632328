//import { useState } from 'react';
//import TextField from '@material-ui/core/TextField';
import { ReactComponent as CloseCross } from '../../../assets/images/cross.svg';
//import { IoIosArrowForward } from 'react-icons/io';
import './Contact.scss';
//import { load } from 'recaptcha-v3';
//import axios from 'axios';

//let validator = require('email-validator');

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Contact(props: Props) {
  //const [message, setMessage] = useState('');
  //const [email, setEmail] = useState('');
  //const [firstname, setFirstname] = useState('');
  //const [lastname, setLastname] = useState('');
  //const [company, setCompany] = useState('');
  //const [phone, setPhone] = useState('');
  //const [errorMessage, setErrorMessage] = useState<String | null>(null);
  //const [successMessage, setSuccessMessage] = useState<String | null>(null);

  /*
  const sendMail = (e: any) => {
    e.preventDefault();
    if (message.length > 3000 || message.length <= 0) {
      setErrorMessage("Message can't be empty and his size should be less than 3000 ");
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return;
    }

    if (firstname.length <= 0) {
      setErrorMessage("Firstname can't be empty");
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return;
    }

    if (lastname.length <= 0) {
      setErrorMessage("Lastname can't be empty");
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return;
    }

    if (!validator.validate(email)) {
      setErrorMessage('Invalid email');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }

    load(process.env.REACT_APP_CAPTCHA || '', {
      useRecaptchaNet: true,
      autoHideBadge: true,
    }).then(recaptcha => {
      recaptcha.execute('action').then(token => {
        axios
          .post(`${process.env.REACT_APP_API_URL!}/contact`, { email, message, firstname, lastname, phone, company, token })
          .then(res => {
            setSuccessMessage(res.data.message);
            setEmail('');
            setMessage('');
            setFirstname('');
            setLastname('');
            setPhone('');
            setCompany('');
            setTimeout(() => {
              setSuccessMessage(null);
              props.setOpen(false);
            }, 3000);
          })
          .catch(err => {
            if (err.response) {
              setErrorMessage(err.response.data.message);
            } else {
              setErrorMessage('Server unavailable, try again later');
            }
            setTimeout(() => {
              setErrorMessage(null);
            }, 3000);
          });
      });
    });
  };
  */

  return (
    <div className={props.open ? 'd-flex flex-column contact overlay' : 'd-none flex-column contact overlay'}>
      <div className='d-flex justify-content-end'>
        <CloseCross
          className='cross closeContactBtn'
          onClick={() => {
            props.setOpen(false);
          }}
        />
      </div>
      <div className='d-flex mt-4 contactContainer'>
        <div className='contactTextContainer'>
          <h2 className='contactTitle'>Contact us</h2>
          <p className='contactText'>
            Want to join the free floating revolution? <br />
            Please, tell us more about your interest or needs <br /> 
            or just have a catch up with our team.<br/>
            <br/>
            Contact us at : <br />
            <a href="mailto:contact@circle-mobility.com">contact@circle-mobility.com</a><br />
            <br/>
            Let’s Run the City together !
            <hr />
            Easily plan your maintenance, repairs and other services by reaching our customer service department at<br />
            <a href="mailto:aftersales@circle-mobility.com">aftersales@circle-mobility.com</a>{' '}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
