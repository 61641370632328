import './CityEnd.scss';

type Props = {
  imageCityEndRef: React.RefObject<HTMLImageElement>;
};

function CityEnd(props: Props) {
  return (
    <section id='cityEndSection' className='d-flex flex-row justify-content-center cityEndSection'>
      <img ref={props.imageCityEndRef} src='images/closing-city/circle-city-close_0.png' alt="" className='closing-city' />
    </section>
  );
}

export default CityEnd;
