const frontLightAnimation = (gsap: any) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.frontLightSection',
      start: 'top top',
      end: 'bottom+=500px top',
      pin: true,

      markers: false,
      scrub: 0.5,
    },
  });

  //Reveal left tag
  tl.to('.leftTagFrontLight', { opacity: 1, duration: 0.5 }, 1.5);

  //Reveal right tag
  tl.to('.rightTagFrontLight', { opacity: 1, duration: 0.5 }, 2);
};

export default frontLightAnimation;
